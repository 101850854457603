import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  color: white;
  background-color: #242424;
  padding: 10px 20px;
  z-index: 1000;

  .logo {
    height: 100%;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }

  .navbar {
    margin-left: auto;
  }

  .burger {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    display: none;
    z-index: 30;
    
    &--1, &--2, &--3 {
      width: 20px;
      height: 2px;
      background-color: white;
      transition: all 0.3s;
      opacity: 1;
    }

    &.active {
      .burger--1 {
        transform: rotate(45deg) translate(5px, 5px);
      }
      .burger--2 {
        transform: translateX(-100%);
        opacity: 0;
      }
      .burger--3 {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    }
  }

  @media (max-width: 640px) {
    .burger {
     display: flex;
    }
  }
`;
