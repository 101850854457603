import React from 'react';
import { NavbarContainer } from './NavbarStyles';

export interface NavbarProps {
  active: boolean
}

export const Navbar = ({active}: NavbarProps) => {
  return <NavbarContainer className={`${active ? 'active' : ''}`}>
      <p className='nav-item' onClick={() => window.scrollTo(0, (document.getElementById('home')?.offsetTop || 0) - 56)}>Home</p>
      <button className='nav-item' onClick={() => window.scrollTo(0, (document.getElementById('services')?.offsetTop || 0) - 56)}>Services</button>
      <button className='nav-item' onClick={() => window.scrollTo(0, (document.getElementById('about')?.offsetTop || 0) - 56)}>About Us</button>
      <p className='nav-item' onClick={() => window.scrollTo(0, (document.getElementById('suppliers')?.offsetTop || 0) - 56)}>Suppliers</p>
      <p className='nav-item' onClick={() => window.scrollTo(0, (document.getElementById('testimonials')?.offsetTop || 0) - 56)}>Testimonials</p>
      <p className='nav-item' onClick={() => window.scrollTo(0, (document.getElementById('projects')?.offsetTop || 0) - 56)}>Projects</p>
  </NavbarContainer>;
};
