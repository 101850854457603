import React from 'react'
import { SuppliersStyles } from './SuppliersStyles'
import certainTeedLogo from '../../assets/certain-teed-logo.svg'
import owensCorningLogo from '../../assets/owens-corning-logo.svg'
import tamkoLogo from '../../assets/tamko-logo.svg'
import gafLogo from '../../assets/gaf-logo.svg'

export const Suppliers = () => {
  return (
    <SuppliersStyles id="suppliers">
        <h2 className='suppliers-title'>
            HIGH QUALITY SUPPLIERS
        </h2>
        <div className="divider"/>
        <div className='suppliers-logos'>
            <div className='suppliers-logo'>
                <img src={certainTeedLogo} alt="" />
            </div>
            <div className='suppliers-logo'>
                <img src={owensCorningLogo} alt="" />
            </div>
            <div className='suppliers-logo'>
                <img src={tamkoLogo} alt="" />
            </div>
            <div className='suppliers-logo'>
                <img src={gafLogo} alt="" />
            </div>
        </div>
        <div className="divider"/>
    </SuppliersStyles>
  )
}
