import React from 'react'
import { ContactStyles } from './ContactStyles'
import { BsWhatsapp } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'

export const Contact = () => {
  return (
    <ContactStyles>
     <div className='contact-text'>
        <p>Contact Us</p> 
      </div> 
     <div className='contact-icons'>
       <a className='contact-icon' href='https://wa.link/tqq3xk' target={'_blank'} rel="noreferrer">
        <BsWhatsapp color='white' /> 
       </a>
       <a className='contact-icon' href='mailto:Jhonpandradem@gmail.com?subject=Roofing Service Information' target={'_blank'} rel="noreferrer">
        <AiOutlineMail color='white' /> 
       </a>
      </div> 
    </ContactStyles>
  )
}
