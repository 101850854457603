import styled from "styled-components";
import TestimonialsBg from '../../assets/banner-image-1.jpeg'

export const TestimonialsStyles = styled.div`
    background-image: linear-gradient(
        to right bottom,
        #8c8c8ca8,
        #d2d2d28d), url(${TestimonialsBg});
    padding: var(--section-padding);
    background-size: cover;
    background-position: center;
    color: white;

    .testimonials {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;

        &-title {
            font-size: var(--h4);
            margin-bottom: 1rem;
            text-shadow: var(--shadow-3);
        }

        &-subtitle {
            font-size: 1.6rem;
            text-shadow: var(--shadow-3);
            margin-bottom: 3rem;
        }

        &-item {
            background-color: white;
            padding: 2rem;
            box-shadow: var(--shadow-3);
            display: flex;
            flex-direction: column;

            &__icon {
                margin-bottom: 1rem;
                width: 30px;
                height: 30px;
            }
            
            &__quote {
                color: black;
                font-size: 1.6rem;
                margin-bottom: 1rem;
            }

            &__name {
                font-size: 1.4rem;
                color: #8d8d8d;
                text-align: right;
                margin-top: auto;
            }
        }
    }

    @media (max-width: 640px) {
        .testimonials {
            display: grid;
            grid-template-columns: repeat(1, 1fr);

            &-item {
                background-color: white;
                padding: 2rem;
                box-shadow: var(--shadow-3);

                &__icon {
                    margin-bottom: 1rem;
                    width: 15px;
                }

                &__quote {
                    font-size: 2rem;
                }

                &__name {
                    font-size: 1.6rem;
                }
            }
        }
    }
`