import styled from 'styled-components';

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  .nav-item {
    font-size: 1.6rem;
    cursor: pointer;
    position: relative;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: inherit;
    font-family: inherit;
  }

  .nav-item::before {
    position: absolute;
    content: '';
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transition: transform 0.5s;
    transform-origin: right;
    transform: scaleX(0);
  }

  .nav-item:hover::before {
    transform: scaleX(1);
    transform-origin: left;
  }

  @media (max-width: 640px) {
    position: fixed;
    top: 56px;
    right: 0;
    flex-direction: column;
    transition: transform 0.6s ease-out;
    transform: translateX(100%);
    opacity: 0;
    z-index: 20;
    background-color: white;
    color: black;
    gap: 0;
    align-items: stretch;
    font-size: 16px;

    
    .nav-item {
      padding: 10px 40px;
      text-align: center;
      transition: all .3s;
      font-weight: bold;
      
      &::before {
         background-color: transparent;
       }

      &:hover {
        background-color: #d1d1d1;
      }
    }

    &.active {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;
