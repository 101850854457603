import React, { useState } from 'react';
import { HeaderContainer } from './HeaderStyles';
import Logo from '../../assets/logo-conintech.svg';
import { Navbar } from '../../components/Navbar';


export const Header = () => {
  const [burgerActive, setBurgerActive] = useState(false)
  return (
    <HeaderContainer>
      <div className="logo">
        <img src={Logo} alt="" />
      </div>
      <div className="navbar">
        <Navbar active={burgerActive} />
      </div>
      <div className={`burger ${burgerActive ? 'active' : ''}`} onClick={() => setBurgerActive(current => !current)}>
        <div className='burger--1'/>
        <div className='burger--2'/>
        <div className='burger--3'/>
      </div>
    </HeaderContainer>
  );
};
