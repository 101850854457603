import styled from 'styled-components';
import bannerImage from '../../assets/banner-background.jpg'

export const BannerContainer = styled.div`
  min-height: calc(100vh - var(--nav-height));
  width: 100%;
  overflow: hidden;
  padding: var(--section-padding);
  background-image: url(${bannerImage});
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ModerneSans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  .banner-title {
    font-weight: 700;
    font-size: var(--h1);
    text-align: center;
    text-transform: uppercase;
    text-shadow: var(--shadow-1);
    color: white;

    &--small {
      display: block;
      text-align: center;
      font-size: 6.4rem;
    }
  }
`;
