import React from 'react';
import { BannerContainer } from './BannerStyles';

export const Banner = () => {

  return (
    <BannerContainer id="home">
      <h1 className="banner-title">
        <span className='banner-title--small'>A construction and installations</span>
        ROOFING COMPANY
      </h1>
    </BannerContainer>
  ); 
};
