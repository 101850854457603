/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ServicesContainer } from './ServicesStyles';
import roofTileImage from '../../assets/roof-tiles.png'
import roofGuttersImage from '../../assets/roof-gutters.png'
import roofFasciaImage from '../../assets/roof-fascia.png'
import roofSoffitImage from '../../assets/roof-soffit.png'
import asphaltRoofImage from '../../assets/asphalt-roof.png'
import metalRoofImage from '../../assets/metal-roof.png'
import flatRoofImage from '../../assets/flat-roof.png'
import tpoRoofImage from '../../assets/tpo-roof.png'

export const Services = () => {

  return (
    <ServicesContainer id="services">
      <h2 className='services-title services-title--left'>THE BEST ON <span className="services-title--bold">CONSTRUCTION,</span></h2>
      <h2 className="services-title services-title--right">
        <span className="services-title--bold">REPLACEMENT</span> AND <span className="services-title--bold">REPAIRMENT</span>
      </h2>
      <div className="services-grid">
        <div className="service">
          <img src={roofTileImage} alt="" className="service__image" />
          <p>Roof Tiles</p>
        </div>
        <div className="service">
          <img src={roofGuttersImage} alt="" className="service__image" />
          <p>Roof Gutters</p>
        </div>
        <div className="service">
          <img src={roofFasciaImage} alt="" className="service__image" />
          <p>Roof Fascia</p>
        </div>
        <div className="service">
          <img src={roofSoffitImage} alt="" className="service__image" />
          <p>Roof Soffit</p>
        </div>
        <div className="service">
          <img src={asphaltRoofImage} alt="" className="service__image" />
          <p>Asphalt Roofs</p>
        </div>
        <div className="service">
          <img src={metalRoofImage} alt="" className="service__image" />
          <p>Metal Roofs</p>
        </div>
        <div className="service">
          <img src={flatRoofImage} alt="" className="service__image" />
          <p>Flat Roofs</p>
        </div>
        <div className="service">
          <img src={tpoRoofImage} alt="" className="service__image" />
          <p>TPO Roofs</p>
        </div>
      </div>
    </ServicesContainer>
  );
};
