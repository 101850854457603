import React from 'react';
import { FooterStyles } from './FooterStyles';
import ConintechLogo from '../../assets/logo-conintech-1.jpg';

export const Footer = () => {
  return <FooterStyles>
      <img className="footer-logo" src={ConintechLogo} alt="" />
    <div className="footer-sections"></div>
    <div className="footer-social">
      {/* <img src={InstagramLogo} alt="" />
      <img src={Twitterlogo} alt="" />
      <img src={FacebookLogo} alt="" /> */}
    </div>
    <div className="footer-legal">
      <p>&copy; Conintech 2022. <br /><span>Some images were extracted from <a href="https://unsplash.com/">Unsplash.com</a></span></p>
    </div>
  </FooterStyles>;
};
