import styled from 'styled-components';
import bannerImage1 from '../../assets/banner-image-1.jpeg';

export const AboutUsContainer = styled.div`
  padding: var(--section-padding);
  background: linear-gradient(to right, #c1c1c1, rgba(255, 255, 255, 0.3)), url(${bannerImage1});
  background-position: center;
  background-size: cover;

  .about {
    &-title {
      width: 100%;
      text-align: center;
      font-size: var(--h2);
      margin: 0 auto;
      margin-bottom: 4rem;
      font-weight: 700;
      text-shadow: var(--shadow-2);
    }
    
    &-description {
      display: flex;
      flex-direction: column;
      font-size: 4rem;
      line-height: 1.25em;
      text-align: center;
      display: flex;
      gap: 4rem;
      max-width: 96rem;
      margin: 4rem auto;
      text-shadow: var(--shadow-3);
    }
  }

  .divider {
    width: 100%;
    height: 2px;
    background-color: #0B0A0A;
  }
`;
