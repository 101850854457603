import styled from "styled-components";

export const ContactStyles = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 0 1.5rem rgba(0,0,0,0.5);
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    user-select: none;
    transition: all 0.3s;
    font-size: 16px;
    overflow: hidden;

    &:hover {
        transform: scale(1.2) translate(-5px, -2px);

        .contact-icons {
            left: 0;
            opacity: 1;
        }

        .contact-text {
            opacity: 0;
        }
    }

    .contact {
        &-text {
            z-index: 1;
            opacity: 1;
            transition: all 0.3s;
        }

        &-icons {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 100%;
            opacity: 0;
            width: 100%;
            height: 100%;
            background-color: #424242;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            transition: all 0.3s;
        }

        &-icon {
            width: 25px;
            height: 25px;
            cursor: pointer;
            outline: none;
            border: none;
            margin: 0;
            padding: 0;
            background-color: transparent;
            transition: all 0.2s;

            svg {
                width: 100%;
                height: 100%;
            }

            &:hover {
                transform: scale(1.1);
                box-shadow: 0 0 1rem #00000017;
            }
        }
    }
`