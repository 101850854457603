import React from 'react'
import { TestimonialsStyles } from './TestimonialsStyles'
import QuoteIcon from '../../assets/quote-icon.svg'

export const Testimonials = () => {
  return (
    <TestimonialsStyles id="testimonials">
      <h4 className="testimonials-title">What customers say about us</h4>
      <p className="testimonials-subtitle">We do our best to provide you the best service ever</p>
      <div className="testimonials">
        <div className='testimonials-item'>
          <img className='testimonials-item__icon' src={QuoteIcon} alt="quote icon" />
          <p className="testimonials-item__quote">Great people - very reliable, clean, friendly and fast. I would recommend then anytime and to anybody</p>
          <p className="testimonials-item__name">- Posted a month ago by Cameron T.</p>
        </div>
        <div className='testimonials-item'>
          <img className='testimonials-item__icon' src={QuoteIcon} alt="quote icon" />
          <p className="testimonials-item__quote">Conintech was very professional and easy to work with. They did both my roof and my mom's. My mom is high maintenance and even she was pleased with Conintech! Would recommend Anstill for anyone in the market for a new roof</p>
          <p className="testimonials-item__name">- Posted a month ago by Jhon D.</p>
        </div>
        <div className='testimonials-item'>
          <img className='testimonials-item__icon' src={QuoteIcon} alt="quote icon" />
          <p className="testimonials-item__quote">Quick and professional roof replacement - started around 7am and were done by 3pm</p>
          <p className="testimonials-item__name">- Posted 2 months ago by Walt C.</p>
        </div>
      </div>
    </TestimonialsStyles>
  )
}
