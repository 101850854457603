import React from 'react';
import { Banner } from '../../sections/Banner';
import { AboutUs } from '../../sections/AboutUs';
import { Services } from '../../sections/Services';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { MainContentContainer, MainContainer } from './MainStyles';
import { Suppliers } from '../../sections/Suppliers';
import { Contact } from '../../components/Contact';
import { Gallery } from '../../sections/Gallery';
import { Testimonials } from '../../sections/Testimonials';

export const Main = () => {

  return (
    <MainContainer>
      <Header />
      <MainContentContainer>
        <Banner />
        <Services />
        <AboutUs />
        <Suppliers />
        <Testimonials />
        <Gallery />
      </MainContentContainer>
      <Footer />
      <Contact />
    </MainContainer>
  );
};
