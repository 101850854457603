/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { AboutUsContainer } from './AboutUsStyles';

export const AboutUs = () => {

  return <AboutUsContainer id='about'>
      <h2 className='about-title'>About Us</h2>
      <div className="divider" />
      <p className="about-description">Our main goal is to provide the best quality and customer satisfaction by combinig construction, innovation and technology. This being possible by our group of professionals, combining youth and experience and  using the latest technologies to provide the highest customer satisfaction</p>
      <div className="divider" />
  </AboutUsContainer>;
};
