import styled from "styled-components";

export const FooterStyles = styled.div`
    padding: var(--section-padding);
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: white;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer {
        &-logo {
            width: 13rem;
            margin-bottom: 1rem;
        }

        &-legal {
            display: flex;
            gap: 2rem;
            text-align: center;

            a {
                color: #a0a0ff;
            }

            span {
                font-size: 0.8em;
                color: #c2c2c2;
            }
        }
    }

    @media (max-width: 640px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 2rem;

        .footer-logo {
            margin-bottom: 0;
            margin-right: 2rem;
        }
    }
`