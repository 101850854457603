import styled from "styled-components";

export const SuppliersStyles = styled.div`
    padding: var(--section-padding);
    background: linear-gradient(102.77deg, #0E0D0D -7.69%, rgba(70, 69, 69, 0.692708) 108.11%, rgba(196, 196, 196, 0) 119.73%);
    color: white;

    .suppliers {
        &-title {
            font-size: var(--h2);
            text-shadow: var(--shadow-2);
            width: 100%;
            text-align: center;
            margin-bottom: 4rem;
        }

        &-logos {
            margin: 4rem 0;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;
        }

        &-logo {
            max-height: 8.3rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: #ffffff;
    }
`