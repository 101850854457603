import styled from 'styled-components';

export const ServicesContainer = styled.div`
  padding: var(--section-padding);
  background: linear-gradient(241.42deg, #202020 32.64%, rgba(5, 5, 5, 0.69) 102.33%, rgba(38, 37, 35, 0) 107.65%);
  color: white;
  
  .services {
    &-title {
      width: 100%;
      text-align: center;
      max-width: 156rem;
      margin: 0 auto;
      font-size: var(--h2);
      text-shadow: var(--shadow-2);
      
      &--left {
        text-align: left;
        margin-bottom: 2rem;
      }
      &--right {
        text-align: right;
        margin-bottom: 4rem;
      }
      &--bold {
        font-weight: 700;
        font-size: 6.4rem;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(150px, 1fr));
      gap: 3rem;
    }
  }

  .service {
    position: relative;
    
    img {
      width: 100%;
      box-shadow: var(--shadow-1);
    }

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem;
      text-align: center;
      font-size: 3.5rem;
      text-shadow: var(--shadow-3);
    }
  }

  @media (max-width: 1024px) {
    .services {
      &-title {
        &--left {
          text-align: center;
          margin-bottom: 0;
        }
        &--right {
          text-align: center;
          margin-bottom: 4rem;
        }
      }

      &-grid {
        grid-template-columns: repeat(3, minmax(150px, 1fr));
      }
    }
  }

  @media (max-width: 640px) {
    .services {
      &-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      }
    }
  }
`;
