import React from 'react'
import { GalleryStyles } from './GalleryStyles'
import Image1 from '../../assets/gallery-image-1-min.jpeg'
import Image2 from '../../assets/gallery-image-2-min.jpeg'
import Image3 from '../../assets/gallery-image-3-min.jpeg'
import Image4 from '../../assets/gallery-image-4-min.jpeg'
import Image5 from '../../assets/gallery-image-5-min.jpeg'
import Image6 from '../../assets/gallery-image-6-min.jpeg'
import Image7 from '../../assets/gallery-image-7-min.jpeg'
import Image8 from '../../assets/gallery-image-8-min.jpeg'
import Image9 from '../../assets/gallery-image-9-min.jpeg'
import Image10 from '../../assets/gallery-image-10-min.jpeg'
import Image11 from '../../assets/gallery-image-11-min.jpeg'
import Image12 from '../../assets/gallery-image-12-min.jpeg'
import Image13 from '../../assets/gallery-image-13-min.jpeg'
import Image14 from '../../assets/gallery-image-14-min.jpeg'

export const Gallery = () => {
  return (
    <GalleryStyles id='projects'>
        <div className="gallery__item--1"><img className='gallery__img' src={Image1} alt="" /></div>
        <div className="gallery__item--2"><img className='gallery__img' src={Image2} alt="" /></div>
        <div className="gallery__item--3"><img className='gallery__img' src={Image3} alt="" /></div>
        <div className="gallery__item--4"><img className='gallery__img' src={Image4} alt="" /></div>
        <div className="gallery__item--5"><img className='gallery__img' src={Image5} alt="" /></div>
        <div className="gallery__item--6"><img className='gallery__img' src={Image6} alt="" /></div>
        <div className="gallery__item--7"><img className='gallery__img' src={Image7} alt="" /></div>
        <div className="gallery__item--8"><img className='gallery__img' src={Image8} alt="" /></div>
        <div className="gallery__item--9"><img className='gallery__img' src={Image9} alt="" /></div>
        <div className="gallery__item--10"><img className='gallery__img' src={Image10} alt="" /></div>
        <div className="gallery__item--11"><img className='gallery__img' src={Image11} alt="" /></div>
        <div className="gallery__item--12"><img className='gallery__img' src={Image12} alt="" /></div>
        <div className="gallery__item--13"><img className='gallery__img' src={Image13} alt="" /></div>
        <div className="gallery__item--14"><img className='gallery__img' src={Image14} alt="" /></div>
    </GalleryStyles>
  )
}
